<template lang="pug">
.v-doc-list.doc-list(v-if="data")
	v-loader(:loading="loading")
	.container.doc-list__container
		.doc-list__header
			h2.doc-list__title.h2 {{data.title}}
		template(v-if="filledCategories && isXs")
			.doc-list__accordion-content
				template(v-for="(category, categoryIndex) in filledCategories" :key="categoryIndex")
					template(v-if="category.id !== 'all'")
						.questions__col.questions__col--full
							.questions__item(@click="openItemSection(category)" :class="{'questions__item--active': category.active}")
								.questions__item-heading
									.questions__item-question {{category.name}}
									.questions__alt-btn(:class="{'close': !category.active}")
										v-svg-icon.questions__alt-icon(icon="chevron-down")
								v-collapse.questions__collapse(:open="category.active")
									.doc-list__item-docs-wrap
										.doc-list__item-docs(v-if="category.items && category.items.length")
											template(v-for="(doc, docIndex) in category.items" :key="docIndex")
												.doc-list__item-doc
													a.doc-item(:href="doc.link")
														img.doc-item__icon(v-if="doc.icon" :src="docIcon" alt="" loading="lazy")
														.doc-item__text {{doc.name}}
		template(v-else-if="!isXs")
			.doc-list__tabs.flc(v-if="data.categories")
				.big-tabs.big-tabs--small
					template(v-for="(category, categoryIndex) in data.categories" :key="categoryIndex")
						button.big-tabs__item(type="button"
								:class="{'active': category.id === activeCategory.id}"
								@click="setActiveCategory(category); filterItems()")
								.big-tabs__item-text {{category.name}}
			.doc-list__content.flc
				.doc-list__items
					template(v-for="(item, index) in (data.categories ? filteredItems : data.items)" :key="index")
						.doc-list__item
							template(v-if="activeCategory && activeCategory.id !== 'all'")
								.doc-list__item-name Найдено:
							template(v-else)
								.doc-list__item-name {{item.name}}
							.doc-list__item-docs-wrap
								.doc-list__item-docs(v-if="item.items && item.items.length")
									template(v-for="(doc, docIndex) in item.items" :key="docIndex")
										.doc-list__item-doc
											a.doc-item(:href="doc.link")
												img.doc-item__icon(v-if="doc.icon" :src="docIcon" alt="" loading="lazy")
												.doc-item__text {{doc.name}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue'
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue'

export default {
	mixins: [
		sourceMixin,
		isMobMixin
	],

	data() {
		return {
			data: null,
			filteredItems: null,
			loading: false,
			noteIcon: '/local/images/bubble-star-azure.svg',
			activeCategory: null,
			docIcon: "/local/images/pdf.svg",
			filledCategories: []
		}
	},

	methods: {
		filterItems() {
			this.filteredItems = this.data.items.filter(item => item.categories ? item.categories.findIndex(category => category === this.activeCategory.id) > -1 : false);
		},

		fillCategories() {
			this.data.categories.forEach(category => {
				this.filledCategories.push({
					id: category.id,
					name: category.name,
					active: category.active,
					items: this.filteredItems?.find(item => item.categories.findIndex(item => item === category.id) > -1)?.items
				})
			})
		},

		setActiveCategory(category) {
			this.activeCategory = category;
		},

		openItem(id) {
			this.data.items.forEach(item => {
				if (item.id === id) {
					item.isOpen = !item.isOpen
				}
			})
		},

		async showMore() {
			this.loading = true
			let options = {
				url: this.data.remote,
				delay: true
			}
			await this.dataLoad(options)
			this.loading = false
		},

		openItemSection(category) {
			this.filledCategories.forEach(item => {
				if (item.id !== category.id) {
					item.active = false;
				}
			});
			category.active = !category.active;
		},

		dataSet(data) {
			this.data = data;
			if (this.data.categories) {
				//this.data.categories.forEach(category => {
				//	this.data.items.forEach(item => {
				//		if (item.categories.findIndex(itemCategory => itemCategory === category.id) > -1) {
				//			category.count++;
				//		}
				//	});
				//});
				this.setActiveCategory(this.data.categories.find(item => item.active));
				this.filterItems();
				this.fillCategories();
			}
		},
	}
}
</script>