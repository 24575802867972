<template lang="pug">
.v-hero-block.hero-block(:class="{'hero-block--color': slide && slide.hint}")
	v-loader(:loading="loading")
	template(v-if="slide")
		a.hero-block__link(
			v-if="slide.link"
			:href="slide.link"
			:aria-label="slide.title")
		.hero-block__bg-layer(v-if="slide.imageSources")
			.hero-block__bg(v-if="slide.imageSources.url")
				.hero-block__bg-image(:style="bgLayer")
		.hero-block__container
			.hero-block__content
				.hero-block__cards
					.tab.hero-block__tab(v-if="slide.price")
						v-svg-icon.tab__icon(v-if="slide.price.icon" :icon="slide.price.icon")
						.tab__text {{ slide.price.value }}
					.tab.hero-block__tab(v-if="slide.hint")
						v-svg-icon.tab__icon.c-orange(v-if="slide.hint.icon" :icon="slide.hint.icon")
						.tab__text {{ slide.hint.text }}
				.hero-block__info(v-if="slide.info")
					.hero-block__info-name {{ slide.info.name }}
					.hero-block__info-text {{ slide.info.text }}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			slide: null
		};
	},

	computed: {
		bgLayer() {
			if (this.slide && this.slide.imageSources) {
				return this.slide.imageSources.bg ?
					`background-image: url(${this.slide.imageSources.url}), url(${this.slide.imageSources.bg})` :
					`background-image: url(${this.slide.imageSources.url})`
			}
		}
	},

	methods: {
		dataSet(data) {
			this.slide = data;
		}
	}
};
</script>