<template lang="pug">
.v-field-file.field-file

	transition(name="fade")
		transition-group.field-file__list.flc(tag="div" name="content-fade" v-if="localFiles.length")
			.field-file__item(v-for="file in localFiles" :key="file.key")
				v-svg-icon.field-file__item-icon(icon="doc")
				.field-file__item-text
					span.field-file__item-name {{file.split[0]}}
					= "."
					span.field-file__item-type {{file.split[1]}}
				button.field-file__item-remove(type="button" @click="remove(file)")
					v-svg-icon.field-file__item-remove-icon(icon="close-sm-round")

	.field-file__main

		input.field-file__input(:id="uniqueId" type="file" @change="fileInput" :key="rerenderKey" ref="input")
		button.field-file__btn(type="button" @click="$refs['input'].click()" @keydown.enter="$refs['input'].click()")
			v-svg-icon.field-file__btn-icon(icon="attach")
			span.field-file__btn-text {{field.label}}
</template>

<script>
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		note: {
			type: String,
			default: null
		},

		field: {
			type: Object,
			default: null
		}
	},
	
	emits: [
		'fileinput',
		'fileremove'
	],

	data() {
		return {
			localFiles: [],
			rerenderKey: 0,
			uniqueId: uniqueId('field-')
		};
	},

	watch: {
		value() {
			this.initLocalValueFromValue();
		}
	},

	created() {
		this.initLocalValueFromValue(true);
	},

	methods: {
		getKey() {
			return uniqueId();
		},

		fileInput(e) {
			let newKey = this.getKey();

			if (!this.field.multiple) {
				this.localFiles.forEach(this.remove);
			}

			// always 1 file per event! (no multiple <input>)
			if (e.target.files?.length) {
				console.log('file input', e.target.files, e.target.files[0]);
				for (let i = 0; i < e.target.files.length; i++) {
					this.localFiles.push({
						name: e.target.files[i].name,
						size: e.target.files[i].size,
						split: e.target.files[i].name.split('.'),
						key: newKey
					});

					console.log(e.target.files[i])
				}

				e.key = newKey;
				this.$emit('fileinput', e);
			}

			this.rerenderKey = this.getKey();
		},

		remove(file) {
			this.localFiles.splice(this.localFiles.indexOf(file), 1);
			this.$emit('fileremove', file);
		},

		initLocalValueFromValue(initial) {
			if (this.field.files && initial) {
				this.localFiles = [];

				this.field.files.forEach((file) => {
					let newKey = this.getKey();

					this.localFiles.push({
						name: file.name,
						size: file.size,
						key: newKey,
						split: file.name.split('.'),
					});

					this.$emit('fileinput', {
						key: newKey,
						target: {files: [file]}
					});
				});
			}
		}
	}
};
</script>
