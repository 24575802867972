export default {
	namespaced: true,

	state: {
		bxajaxid: null,
		remote: null,
		link: null,
		items: [],
		loading: false
	},

	getters: {
		favoriteEmpty(state) {
			return !state.items || !state.items.length;
		},

		favoriteCount(state) {
			return state.items ? state.items.length : 0;
		}
	},

	mutations: {
		set(state, data) {
			state.bxajaxid = data.bxajaxid;
			state.remote = data.remote;
			state.link = data.link;
			state.items = data.items;
		},

		setLoading(state, value) {
			state.loading = value;
		}
	},

	actions: {
		async request(context, options) {
			context.commit('setLoading', true);

			const response = await fetch(context.state.remote, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					...options,
					bxajaxid: context.state.bxajaxid
				})
			});

			if (!response.ok) {
				console.warn('[vuex] favorite: AJAX request failed', response);
			} else {
				const data = await response.json();
				console.log('[vuex] favorite: loaded', data);
				context.commit('set', data);
			}

			context.commit('setLoading', false);
		},

		async add(context, options) {
			context.dispatch('request', {
				action: 'add',
				id: options.id
			});
		},

		async remove(context, options) {
			context.dispatch('request', {
				action: 'remove',
				id: options.id
			});
		}
	}
};