<script>
import controller from "../../scripts/controller/controller.js";

export default {
	props: {
		// link to load data from (String)
		// or data object itself (Object)
		dataSource: {
			type: [Object, String],
			default: undefined
		},

		// Bitrix component id
		// required by Bitrix to determine component instance for AJAX requests
		// (dataSource link often simpty refers to page containing multiple components)
		bxajaxid: {
			type: String,
			default: undefined
		},

		// immediately load data on "created" hook
		// if dataSource is a link
		loadOnInit: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			// bxajaxid which is finally computed after processing props and slots
			localBxajaxid: null,

			// loading state
			loading: false
		};
	},

	computed: {
		componentName() {
			let path = this.$options.__file;
			if (path) {
				return path.slice(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));
			}
			return null;
		}
	},

	watch: {
		dataSource() {
			// try to load using props
			this.init(this.bxajaxid, this.dataSource);
		}
	},

	created() {
		// try to load using props
		this.init(this.bxajaxid, this.dataSource);

		const readSlot = (item) => {
			const bxajaxid = item.props['bxajaxid'];
			const dataSource = item.props['data-source'];

			if (dataSource) {
				this.init(bxajaxid, dataSource);
			}
		};

		// try to load using slots
		if (this.$slots?.source) {
			this.$slots?.source().forEach((item) => {
				readSlot(item);
				item.children?.forEach(readSlot);
			});
		}
	},

	methods: {
		dataSet(data, options) {
			console.info(`${this.componentName}: warning: v-source-mixin is used but sourceSet is not implemented in your component`, data, options);
		},

		async dataLoad(options = {}, dataSetOptions) {
			// use this.loading to set preloaders if needed
			this.loading = true;
			// check arguments
			if (!options || typeof options !== 'object' || !options.url) {
				console.warn(`${this.componentName}: warning: dataLoad options arg is wrong`, options);
			}
			// convert any options.data to transfer as FormData
			let formattedData = new FormData();
			if (options.data instanceof FormData) {
				formattedData = options.data;
			} else if (options.data) {
				let makeFormData = (data, parentKey) => {
					if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
						Object.keys(data).forEach((key) => {
							makeFormData(data[key], parentKey ? `${parentKey}[${key}]` : key);
						});
					} else {
						formattedData.append(parentKey, data == null ? '' : data);
					}
				};
				makeFormData(options.data);
			}
			// set bxajaxid if present
			if (this.localBxajaxid) {
				formattedData.set('bxajaxid', this.localBxajaxid);
			}
			// set min delay to ensure preloader fadein/fadeout plays fine (<transition name="fade"> speed is 250ms)
			const delayPromise = new Promise((resolve) => {
				options.delay ? setTimeout(resolve, 250) : resolve();
			});
			try {
				// create request
				const requestPromise = fetch(options.url, {
					method: 'POST',
					body: formattedData
				});
				// wait for both promises
				const [response] = await Promise.all([requestPromise, delayPromise]);
				// handle response
				if (!response.ok) {
					console.warn(`${this.componentName}: AJAX request failed`, response);
				} else {
					let data = await response.json();
					if ((typeof (data.encoded) !== 'undefined')) {
						data = JSON.parse(atob(data.encoded));
					}
					console.log(`${this.componentName}: AJAX request completed`, data);

					if (data.redirect) {
						window.location.href = data.redirect;
					}
					if (data.reload) {
						window.location.reload(true);
					}

					if (data.displayURL) {
						window.history.replaceState(null, null, data.displayURL);
					}

					if (data.openModal) {
						controller.openModal({
							className: data.modal.className,
							url: data.modal.url,
						})
					}

					this.dataSet(data, dataSetOptions);
					if (!data.redirect && !data.reload) {
						this.loading = false;
					}
				}
			} catch (e) {
				console.warn(`${this.componentName}: AJAX request failed`, e);
			}
			this.loading = false;
		},

		init(bxajaxid, dataSource) {
			if (typeof bxajaxid !== 'undefined') {
				this.localBxajaxid = bxajaxid;
			}

			if (typeof dataSource === 'string') {
				if (this.loadOnInit) {
					this.dataLoad({url: dataSource});
				}
			} else if (typeof dataSource !== 'undefined') {
				if ((typeof (dataSource.encoded) !== 'undefined')){
					dataSource = JSON.parse(atob(dataSource.encoded));
				}
				this.dataSet(dataSource);
			}
		}
	}
};
</script>