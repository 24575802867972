<template lang="pug">
.v-field-check-list.field-check-list(:class="{'field-check-list--open': open}")
	label.field-check-list__label(v-if="field.label" :id="uniqueId") {{field.label}}
	.field-check-list__items
		.field-check-list__item.flc(v-for="(option, index) in firstItems" :key="option.value + '-' + index")
			v-field-checkbox(
					:true-value="option.value"
					:text="option.text"
					:disabled="option.disabled || field.disabled"
					v-model="localValue"
					@update:model-value="handleInput"
					:radio="radio")
	v-collapse(:open="open")
		.field-check-list__items(v-if="hiddenItems")
			.field-check-list__item.flc(v-for="(option, index) in hiddenItems" :key="option.value + '-' + index")
				v-field-checkbox(
						:true-value="option.value"
						:text="option.text"
						:disabled="option.disabled || field.disabled"
						v-model="localValue"
						@update:model-value="handleInput"
						:radio="radio")
	button.field-check-list__action(v-if="hiddenItems" type="button" @click="open = !open") {{open ? 'Свернуть' : 'Посмотреть все'}}
</template>

<script>
import uniqueId from '../scripts/helpers/uniqueId.js';

export default {
	props: {
		radio: {
			type: Boolean,
			default: false
		},

		field: {
			type: Object,
			required: true,
			default: null
		},

		modelValue: {
			type: [Object, String, Number, Array, Boolean],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null,
			open: false,
			uniqueId: uniqueId('field-'),
			visibleCount: 8
		};
	},

	computed: {
		firstItems() {
			return this.field?.options?.length > this.visibleCount ? this.field.options.slice(0, this.visibleCount) : this.field.options;
		},

		hiddenItems() {
			return this.field?.options?.length > this.visibleCount ? this.field.options.slice(this.visibleCount, this.field.options.length) : null;
		}
	},

	watch: {
		modelValue() {
			this.updateLocalValue();
		}
	},

	created() {
		this.updateLocalValue();
	},

	methods: {
		updateLocalValue() {
			this.localValue = this.modelValue;
		},

		handleInput(value, field) {
			this.$emit('update:model-value', value, field);
		}
	},
};
</script>
