<template lang='pug'>
.v-search-small.search-small(v-if="search")
	.search-small__wrap
		template(v-if="search.field")
			form.search-small__form(@submit.prevent="relocate" @reset.prevent="close")
				v-field(:field="search.field" v-model="localQuery" class="search-small__field" ref="field-search")
				button.search-small__close-btn(v-if="localQuery && localQuery.length > 0" aria-label="Очистить" type="reset")
					v-svg-icon.search-small__close-btn-icon(icon="search-close")
				v-svg-icon.search-small__search-icon(icon="search")
		.search-small__result
			.search-small__loader-wrap(v-if="localQuery && localQuery.length > 2 && localQuery !== search.query" :class="{'visible': localQuery !== search.query}")
				v-loader(:loading="loading")
			.search-small__items.scrollbar(v-if="search.items && search.items.length > 0 && localQuery && localQuery.length > 2")
				template(v-for="(item, itemIndex) in search.items" :key="itemIndex")
					.search-small__item
						v-product(:product="item" template="search-small-item")
			.search-small__result-message(v-else-if="localQuery && localQuery.length > 2") {{search.emptyText}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import controller from '../scripts/controller/controller.js';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			loading: false,
			localQuery: null,
			searchTimeout: null,
			search: {
				remote: null,
				query: null,
				emptyText: null,
				field: null,
				items: null
			},
			activeCategory: null
		};
	},

	watch: {
		localQuery() {
			this.searchInput();
		}
	},

	created() {
		//document.addEventListener('click', this.clickOutside);
		controller.events.on('search-small-open', this.open);
		controller.events.on('search-small-close', this.close);
		controller.events.on('search-small-focus', this.focus);
	},

	unmounted() {
		//document.removeEventListener('click', this.clickOutside);
	},

	methods: {
		setActiveCategory(category) {
			this.activeCategory = category;
		},

		relocate() {
			if (this.search.link) {
				let searchUrl = new URL(window.location.protocol + window.location.host + this.search.link);
				searchUrl.searchParams.append('query', this.localQuery);
				window.location = searchUrl;
			}
		},

		open() {
			document.body.classList.add('state--search-small-open');
		},

		close() {
			this.localQuery = null;
			this.search.items = null;
			document.body.classList.remove('state--search-small-open');
		},

		focus() {
			this.$refs['field-search']?.focusSearch();
		},

		clickOutside(e) {
			if (!this.$el.contains(e.target)) {
				controller.closeSearchSmall();
			}
		},

		searchInput() {
			clearTimeout(this.searchTimeout);
			if (this.localQuery) {
				controller.openSearchSmall();
				this.searchTimeout = setTimeout(this.requestDataLoad, 200);
			} else {
				controller.closeSearchSmall();
			}
		},

		requestDataLoad() {
			if (this.localQuery.length > 2) {
				this.dataLoad({
					url: this.search.remote,
					delay: true,
					data: {
						query: this.localQuery,
						bxajaxid: this.bxajaxid
					}
				});
			}
		},

		dataSet(data) {
			if (!this.search.remote) {
				this.search = data;
			}
			if (this.localQuery && this.localQuery === data.query) {
				this.search = data;
			}
			if (this.search && this.search.categories && this.search.items && this.search.items.length) {
				this.setActiveCategory(this.search.categories.find(item => item.active));
			}
		}
	}
};
</script>