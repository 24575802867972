<template lang="pug">
.v-double-slider.double-slider
	.double-slider__container.container
		h2.double-slider__title.h2(v-if="slider.title") {{slider.title}}
		.double-slider__wrap.flc
			button.hero-slider__arrow.hero-slider__arrow--prev(type="button" ref="prev" :class="`${uniqueId}`")
				v-svg-icon.hero-slider__icon(icon="slider-left")
			button.hero-slider__arrow.hero-slider__arrow--next(type="button" ref="next" :class="`${uniqueId}`")
				v-svg-icon.hero-slider__icon(icon="slider-right")
			v-swiper-slider(v-if="slider && slider.items && slider.items.length"
					:options="computedOptions"
					:navigation="computedNavigation"
					:key="uniqueId"
					:pagination="pagination"
					:ref="`swiperSlider-${uniqueId}`")
				v-swiper-slide(v-for="(slide, index) in slider.items" :key="slide.id || index")
					.num-card.num-card--small
						.num-card__index {{index < 9 ? 0 : ''}}{{index + 1}}
						.num-card__small-title(v-if="slide.title") {{slide.title}}
						.num-card__content.flc.text-guide(v-if="slide.text" v-html="slide.text")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';
import {SwiperSlide} from 'swiper/vue';

export default {
	components: {
		'v-swiper-slide': SwiperSlide
	},

	mixins: [
		sourceMixin
	],

	props: {
		options: {
			type: Object,
			default: null
		},
		pagination: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			slider: {},
			uniqueId: uniqueId('swiper-')
		};
	},

	computed: {
		computedOptions() {
			let result = {
				slidesPerView: 1,
				spaceBetween: 12,
				loop: false,
				breakpoints: {
					1024: {
						slidesPerView: 2,
						spaceBetween: 46
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 12
					}
				}
			};
			if (this.options) {
				Object.keys(this.options).forEach((key) => {
					if (typeof this.options[key] !== 'undefined') {
						result[key] = this.options[key];
					}
				});
			}
			return result;
		},

		computedNavigation() {
			return {
				prevEl: `.hero-slider__arrow--prev.${this.uniqueId}`,
				nextEl: `.hero-slider__arrow--next.${this.uniqueId}`
			};
		}
	},

	methods: {
		dataSet(data) {
			this.slider = data;
		}
	}
};
</script>