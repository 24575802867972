<template lang="pug">
.v-field-radio.field-radio(v-if="field")
	.field-radio__label(v-if="field.label") {{field.label}}
	label.field-radio__item(v-for="(option, index) in field.options" :key="index")
		input.field-radio__input(:checked="option.value === localValue" type="radio" :name="field.id" @change="handleInput(option.value)")
		span.field-radio__text(v-if="option.text" v-html="option.text")
</template>

<script>


export default {
	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: [Object, String, Number, Array, Boolean],
			default: null
		}
	},

	emits: [
		'update:model-value'
	],

	data() {
		return {
			localValue: null
		};
	},

	created() {
		this.localValue = this.field.value;
	},

	watch: {
		modelValue() {
			this.updateLocalValue();
		}
	},

	methods: {
		updateLocalValue() {
			this.localValue = this.modelValue;
		},

		handleInput(value) {
			this.localValue = value;
			this.$emit('update:model-value', this.localValue, this.field);
		}
	}
};
</script>
