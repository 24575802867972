<template lang="pug">
transition(
	name="collapse"
	:duration="duration"
	@before-enter="closeState"
	@enter="openState"
	@after-enter="cleanState"
	@before-leave="openState"
	@leave="closeState"
	@after-leave="cleanState")
	template(v-if="open")
		slot
</template>

<script>
export default {
	props: {
		open: {
			type: Boolean,
			default: false
		},

		duration: {
			type: Number,
			default: null
		}
	},

	data() {
		return {
			animated: false,
			animatedTimeout: 250,
			animatedTimeoutId: null
		};
	},

	methods: {
		openState(el) {
			el.style.height = el.scrollHeight + 'px';
			el.style.marginTop = '';
			el.style.marginBottom = '';
			el.style.paddingTop = '';
			el.style.paddingBottom = '';
			this.animated = true;
		},

		closeState(el) {
			el.style.height = '0';
			el.style.marginTop = '0';
			el.style.marginBottom = '0';
			el.style.paddingTop = '0';
			el.style.paddingBottom = '0';
			this.animated = true;
		},

		cleanState(el) {
			el.style.height = '';
			el.style.marginTop = '';
			el.style.marginBottom = '';
			el.style.paddingTop = '';
			el.style.paddingBottom = '';
			this.animatedTimeoutId = null;
			this.animatedTimeoutId = setTimeout(() => {
				this.animated = false;
			}, this.animatedTimeout);
		}
	}
};
</script>