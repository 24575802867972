<template lang="pug">
.v-accordion.accordion(v-if="data")
	template(v-if="data.items && data.items.length")
		.accordion__item(v-for="(item, index) in data.items" :class="{'open': item.isOpen}" :key="index")
			.accordion__item-head(@click="openItem(item, index)")
				h3.accordion__title {{item.title}}
				.accordion__btn
					v-svg-icon.accordion__icon(icon="chevron-down")
			v-collapse(:open="item.isOpen")
				.accordion__body.text-guide(v-html="item.text")
</template>

<script>
import sourceMixin from '../mixins/v-mixin-source.vue'

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null
		}
	},

	methods: {
		dataSet(data) {
			this.data = data;
		},

		openItem(item, itemIndex) {
			this.data.items.forEach((item, index) => {
				if (itemIndex !== index) {
					item.isOpen = false;
				}
			});
			item.isOpen = !item.isOpen;
		}
	}
}
</script>