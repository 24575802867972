<template lang="pug">
.v-selection-table.selection-table(v-if="table")
	.selection-table__title(v-if="table.title") {{table.title}}
	.selection-table__filters(v-if="table.filters")
		.selection-table__filter(v-for="filter in table.filters" :key="filter.id")
			v-field.selection-table__filter-field(:field="filter"
				appearance="form"
				v-model="filter.value"
				@update:model-value="filterChanged")
	.selection-table__table(v-if="table.items && table.items.length")
		.selection-table__rows
			.selection-table__row(v-for="(row, rowInd) in table.items" :key="rowInd" :class="{'selection-table__row--head': row.head}")
				.selection-table__columns(v-if="row.items && row.items.length")
					.selection-table__column(v-for="(col, colInd) in row.items" :key="colInd")
						component(:is="col.link ? 'a' : 'div'" :class="col.link ? 'selection-table__link' : 'selection-table__text'" :href="col.link ? col.link : null") {{col.text}}
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import isMobMixin from '../components/mixins/v-mixin-is-mob.vue';

export default {
	mixins: [
		sourceMixin,
		isMobMixin
	],

	data() {
		return {
			table: null
		};
	},

	methods: {
		dataSet(data) {
			this.table = data;
		},

		getFiltersValue() {
			let result = {};
			console.log('getFiltersValuem', this.table, this.table.filters, this.table.filters.length)
			if (this.table && this.table.filters && this.table.filters.length > 0) {
				this.table.filters.forEach(filter => {
					console.log('filter', filter)
					result[filter.name] = {
						value: filter.value,
						type: filter.type
					};
				});
			}
			return result;
		},

		filterChanged() {
			this.dataLoad({
				url: this.table.remote,
				delay: true,
				data: {
					filters: this.getFiltersValue()
				}
			});
		},
	}
};

</script>
