<template lang="pug">
.v-filter-active-items.active-filters
	transition-group.active-filters__grid(name="flip" tag="div")
		template(v-for="(filter, index) in activeFilters" :key="filter.id + '-' + filter.value || index")
			button.catalog__selected-item(type="button" @click="resetFilter(filter)")
				span.catalog__selected-item-text {{filter.text}}
				.catalog__selected-item-btn
					v-svg-icon.catalog__selected-item-btn-icon(icon="close-sm")
	button.catalog__selected-clear(type="button" @click="resetAllFilters") {{variables.Reset_all_filters}}
</template>

<script>
export default {
	props: {
		filters: {
			type: Array,
			default: null
		},

		variables: {
			type: Object,
			default: null
		}
	},

	emits: [
		'reset-filter',
		'reset-all-filters'
	],

	computed: {
		activeFilters() {
			let result = [];
			if (!this.filters) {
				return result;
			}
			this.filters.forEach((filter) => {
				if (filter.options) {
					filter.options.forEach((option) => {
						if (Array.isArray(filter.value) && filter.value.indexOf(option.value) >= 0 || option.value === filter.value) {
							result.push({
								id: filter.id,
								value: option.value,
								text: option.text || option.value
							});
						}
					});
				} else {
					let text;
					if (filter.type === 'range' || filter.type === 'range-slider') {
						text = `${filter.title} ${filter.prefixMin} ${filter.value.min} ${filter.postfixMin} ${filter.prefixMax} ${filter.value.max} ${filter.postfixMax}`;
					} else {
						text = `${filter.title} ${filter.value}`;
					}
					result.push({
						id: filter.id,
						value: filter.value,
						text: text
					});
				}
			});
			return result;
		}
	},

	methods: {
		resetAllFilters() {
			this.$emit('reset-all-filters');
		},

		resetFilter(filter) {
			this.$emit('reset-filter', filter);
		}
	}
};
</script>