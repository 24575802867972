export default {
	namespaced: true,

	state: {
		bxajaxid: null,
		remote: null,
		link: null,
		items: [],
		loading: false
	},

	getters: {
		basketPrice(state) {
			if (state.items && state.items.length) {
				let value = state.items.reduce((summ, item) => {
					try {
						return summ + item.price.current.value;
					} catch (e) {
						return summ;
					}
				}, 0);

				let currency;

				try {
					currency = state.items[0].price.current.currency;
				} catch (e) {
					currency = null;
				}

				return {
					current: {
						value,
						currency
					}
				};
			} else {
				return null;
			}
		},

		basketEmpty(state) {
			return !state.items || !state.items.length;
		},

		basketCount(state) {
			return state.items ? state.items.length : 0;
		},

		basketCountByItem(state) {
			return (id) => {
				let result = state.items.find(item => item.id === id);
				return result ? result.count : 0;
			};
		}
	},

	mutations: {
		set(state, data) {
			state.bxajaxid = data.bxajaxid;
			state.remote = data.remote;
			state.link = data.link;
			state.items = data.items;
		},

		setLoading(state, value) {
			state.loading = value;
		}
	},

	actions: {
		async request(context, options) {
			context.commit('setLoading', true);

			const response = await fetch(context.state.remote, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					...options,
					bxajaxid: context.state.bxajaxid
				})
			});

			if (!response.ok) {
				console.warn('[vuex] basket: AJAX request failed', response);
			} else {
				const data = await response.json();
				console.log('[vuex] basket: loaded', data);
				context.commit('set', data);
			}

			context.commit('setLoading', false);
		},

		async add(context, options) {
			context.dispatch('request', {
				action: 'add',
				id: options.id,
				count: options.count
			});
		},

		async remove(context, options) {
			context.dispatch('request', {
				action: 'remove',
				id: options.id
			});
		}
	}
};