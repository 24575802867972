<template lang="pug">
.v-hero-slider.hero-slider
	v-loader(:loading="loading")
	button.hero-slider__arrow.hero-slider__arrow--prev(type="button" ref="prev" :class="`${uniqueId}`")
		v-svg-icon.hero-slider__icon(icon="slider-left")
	button.hero-slider__arrow.hero-slider__arrow--next(type="button" ref="next" :class="`${uniqueId}`")
		v-svg-icon.hero-slider__icon(icon="slider-right")
	v-swiper-slider(v-if="slider && slider.slides && slider.slides.length"
			:options="computedOptions"
			:navigation="computedNavigation"
			:key="uniqueId"
			:ref="`swiperSlider-${uniqueId}`")
		v-swiper-slide(v-for="(slide, index) in slider.slides" :key="slide.id || index")
			v-product(template="grid" :product="slide")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import uniqueId from '../scripts/helpers/uniqueId.js';
import {SwiperSlide} from 'swiper/vue';

export default {
	components: {
		'v-swiper-slide': SwiperSlide
	},

	mixins: [
		sourceMixin
	],

	props: {
		options: {
			type: Object,
			default: null
		},
		pagination: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			slider: {},
			uniqueId: uniqueId('swiper-')
		};
	},

	computed: {
		computedOptions() {
			let result = {
				slidesPerView: 1,
				spaceBetween: 0
			};
			if (this.options) {
				Object.keys(this.options).forEach((key) => {
					if (typeof this.options[key] !== 'undefined') {
						result[key] = this.options[key];
					}
				});
			}
			return result;
		},

		computedNavigation() {
			return {
				prevEl: `.hero-slider__arrow--prev.${this.uniqueId}`,
				nextEl: `.hero-slider__arrow--next.${this.uniqueId}`
			};
		}
	},

	methods: {
		dataSet(data) {
			this.slider = data;
		}
	}
};
</script>